.ant-table-body::-webkit-scrollbar-track
{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 8px;
	background-color: #fafafa; //#2d2a3a;
}

.ant-table-body::-webkit-scrollbar
{
	width: 8px;
  background-color: #fafafa;//#2d2a3a;
  height: 8px;
}

.ant-table-body::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color:#d9d9d9;//#2d2a3a;
}
.ant-table-body::-webkit-scrollbar-corner{
  background-color:#fafafa;//#2d2a3a;
}
.ant-table-body::-webkit-scrollbar-button{
  //background-color:#fafafa;//#2d2a3a;
}
.ant-table-body::-webkit-scrollbar-corner{
    background-color:#fafafa;//#2d2a3a;
}
.ant-table-body::-webkit-resizer {
background-color:#fafafa;//#2d2a3a;
}
.ant-table-fixed-right{
    .ant-table-body-inner::-webkit-scrollbar-track
    {
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 8px;
        background-color: #fafafa; //#2d2a3a;
    }

    .ant-table-body-inner::-webkit-scrollbar
    {
        width: 8px;
    background-color: #fafafa;//#2d2a3a;
    height: 8px;
    }

    .ant-table-body-inner::-webkit-scrollbar-thumb
    {
        border-radius: 8px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color:#fafafa;//#2d2a3a;
    }
    .ant-table-body-inner::-webkit-scrollbar-corner{
    background-color:#fafafa;//#2d2a3a;
    }
    .ant-table-body-inner::-webkit-scrollbar-button{
     background-color:#fafafa;//#2d2a3a;
    }
    .ant-table-body-inner::-webkit-scrollbar-corner{
        background-color:#fafafa;//#2d2a3a;
    }
    .ant-table-body-inner::-webkit-resizer {
    background-color:#fafafa;//#2d2a3a;
    }
}

.avatar-uploader > .ant-upload {
  // width: 128px;
  // height: 128px;
}
.avatar-uploader{
  width: 104px;
  height: 104px;
}

.indentity-uploader .ant-upload {
  width: 100%;
  height: 150px;
  //background-color: #2d2a3a;
  
  //border-color: #828288;
  //border-width: 2px;
}
.indentity-uploader .ant-upload {
  width: 100%;
  height: 150px;
  //background-color: #2d2a3a;
  //border-color: #828288;
  //border-width: 2px;
}
.ant-modal-body{
  padding-top: 5px;
  padding-bottom: 5px;
}
.ant-table {
  display: block;

  @media screen and (max-width: 600px) {
    // &-thead {
    //   display: none;
    // }

    &-thead>tr,
    &-tbody>tr {

      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }

        &:last-of-type {
          padding-bottom: 1rem;
        }
      }

      >th,
      >td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0 1rem;
        font-size: 1.1rem;

        &:last-child {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}
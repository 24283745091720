
.ant-notification{
    z-index: 1040;
}
.anticon {
    vertical-align: 0.1em !important;
}

.loading-page{
    width: 100%;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1500;
    display: flex;
    align-items: center;
    justify-items: center;
    /* opacity: 1.0; */
    background-color: rgba(255, 255,255, 0.5);
}
.loading-grid{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

